@media screen and (max-width: 768px) {
  .main-panel .main-content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 991px) {
  .nav-open .main-panel{
    position: fixed;
  }
}
