.before-collapsing {
  display: block !important;
  overflow: hidden;
}
.collapsing {
  // transition: height 10s ease;
}
ngb-accordion {
  .card {
    box-shadow: none;
    background: transparent;
    .card-header {
      padding: 0.75rem 0;
      position: relative;
      [aria-expanded="true"] .arrows-1_minimal-down {
        @include rotate-180();
      }
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: $light-gray;
      }
      button {
        display: block;
        border-bottom: none !important;
        width: 100%;
        margin: 0;
        padding: 0;
        text-align: left;
        font-size: 14px;

        &,
        &:hover,
        &:focus,
        &:active {
          background: inherit !important;
          color: #444 !important;
        }
        &:hover,
        &:active {
          text-decoration: underline;
        }

        .arrows-1_minimal-down {
          float: right;
          position: relative;
          color: $primary-color;
          top: 1px;
          @include transition($general-transition-time, $transition-ease);
        }
      }
    }
    // .card-body{
    //     display: none;
    //     position: relative;
    //     overflow: hidden;
    //     &.show{
    //         display: block;
    //         // @include transition(300ms, linear);
    //     }
    // }
  }
  // .card:not(.card-plain):hover{
  //     transform: none!important;
  // }
}
