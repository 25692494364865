// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.

.nav-tabs {
  border: 0;
  padding: $padding-large-vertical $padding-base-horizontal;

  > .nav-item {
    > .nav-link {
      color: $default-color;
      margin: 0;
      margin-right: 5px;
      background-color: $transparent-bg;
      border: 1px solid $transparent-bg;
      border-radius: 310px;
      font-size: $font-size-base;
      padding: $padding-btn-vertical $padding-round-horizontal;
      line-height: $line-height-general;

      &:hover {
        background-color: $transparent-bg;
      }

      &.active {
        border: 1px solid $default-color;
        border-radius: 30px;
      }

      i.now-ui-icons {
        font-size: 14px;
        position: relative;
        top: 1px;
        margin-right: 3px;
      }
    }

    &.disabled > .nav-link,
    &.disabled > .nav-link:hover {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  &.nav-tabs-neutral {
    > .nav-item {
      > .nav-link {
        color: $white-color;

        &.active {
          border-color: $opacity-5;
          color: $white-color;
        }
      }
    }
  }

  &.nav-tabs-primary {
    > .nav-item {
      > .nav-link {
        &.active {
          border-color: $primary-color;
          color: $primary-color;
        }
      }
    }
  }

  &.nav-tabs-info {
    > .nav-item {
      > .nav-link {
        &.active {
          border-color: $info-color;
          color: $info-color;
        }
      }
    }
  }

  &.nav-tabs-danger {
    > .nav-item {
      > .nav-link {
        &.active {
          border-color: $danger-color;
          color: $danger-color;
        }
      }
    }
  }

  &.nav-tabs-warning {
    > .nav-item {
      > .nav-link {
        &.active {
          border-color: $warning-color;
          color: $warning-color;
        }
      }
    }
  }

  &.nav-tabs-success {
    > .nav-item {
      > .nav-link {
        &.active {
          border-color: $success-color;
          color: $success-color;
        }
      }
    }
  }
}
.accordion-item {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: none;
  background: 0 0;
  border: 0;
  border-radius: 0.25rem;
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;

  .accordion-header {
      padding: 0.75rem 0;
      position: relative;
      border-bottom: 0;
      background-color: transparent;
      border-radius: 0;
      margin-bottom: 0;
      border-bottom: 1px solid #e3e3e3;

      .accordion-button {
          margin: 0 0 10px;
          background: inherit !important;
          color: #444 !important;
          display: block;
          border-bottom: none !important;
          width: 100%;
          padding: 0;
          text-align: left;
          font-size: 14px;
          font-weight: 400;
          border: 0;
          border-radius: 0.1875rem;
          text-transform: none;
          outline: none;

          a {
              text-decoration: none;
              border-bottom: 2px solid transparent;
              color: #444;
              background-color: transparent;

              i {
                  float: right;
                  color: #f96332;
              }
          }
      }

      .accordion-button[aria-expanded="true"] {
          a {
              i {
                  transform: rotate(180deg);
              }
          }
      }

  }
}

.accordion-body {
  flex: 1 1 auto;
  padding: 0.75rem;
}