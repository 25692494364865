.sidebar,
.off-canvas-sidebar {
  .logo-img {
    width: 42px;
    max-height: 42px;

    img {
      width: 42px;
      top: 10px;
      left: 18px;
      position: absolute;
    }
  }
  .nav span {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    -ms-transition: none !important;
    transition: none !important;
  }
}
.main-panel {
  .main-content {
    padding: 0px !important;
    margin-top: -30px;
  }

  .navbar {
    margin-bottom: 0;
  }
}
.panel-header {
  @include panel-gradient($primary-navbar);
}

@media screen and (max-width: 1600px) {
  .main-panel {
    .main-content {
      margin-top: -40px;
    }
  }
}
